import useLocation from "@hooks/useLocation"
import { parseHTML } from "@lib/utils"
import { graphql, useStaticQuery } from "gatsby"
import { isEmpty } from "lodash"
import loadable from "@loadable/component"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import "./DeveloperInfo.scss"
import GGFXImage from "@components/elements/GGFXImage"
const VideoAskWidget = loadable(() => import("@components/VideoAsk/VideoAsk"))
const DeveloperInfo = () => {
  const { pathname } = useLocation()
  const data = useStaticQuery(graphql`
    query {
      allStrapiDeveloper(filter: { publish: { eq: true } }) {
        nodes {
          ...developerFragment
        }
      }
    }
  `)
  const developers = data?.allStrapiDeveloper?.nodes

  const [developer, setDeveloper] = useState(null)

  useEffect(() => {
    const arr = pathname.split("/")
    const developerSlug = arr.find((item) => item.includes("developed-by"))

    if (developerSlug) {
      const selectedDeveloper = developers.find(
        (item) => item.slug === developerSlug.replace("developed-by-", "")
      )

      setDeveloper(selectedDeveloper)
    } else {
      setDeveloper(null)
    }
  }, [developers, pathname])
  if (isEmpty(developer)) return null

  return (
    <div className="developer-info-wrap" key={developer?.slug}>
      <Container className="developer-info-container">
        <div className="img-section">
          {/* <img src={developer?.logo?.url} alt="developer-logo" /> */}
          <GGFXImage
                      ImageSrc={developer?.logo}
                      altText={developer?.name}
                      imagetransforms={developers?.ggfx_results}
                      renderer="srcSet"
                      imagename="developer.logo.slider_logo_big"
                      strapiID={developer?.strapi_id}
                      lazyLoading
                    />
        </div>
        <div className="content-section">
          <p className="name">{developer?.name}</p>
          <div className="description">
            {parseHTML(developer?.description?.data?.description)}
          </div>
        </div>
       
      </Container>
      {developer?.video_ask_url && <VideoAskWidget video_ask_url={developer?.video_ask_url} />
    }
    </div>
  )
}

export default DeveloperInfo
