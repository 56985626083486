import BannerBG from "@components/Banner/BannerBG"
import DeveloperInfo from "@components/DeveloperInfo/DeveloperInfo"
import Layout from "@components/Layout/Layout"
import ResultsBreadcrumb from "@components/PropertyBreadcrumb/ResultsBreadcrumb"
import InfoMapSort from "@components/SearchResults/InfoMapSort/InfoMapSort"
import PropertyList from "@components/SearchResults/PropertyList/PropertyList"
import SearchFilters from "@components/SearchResults/SearchFilters/SearchFilters"
import SearchPagination from "@components/SearchResults/SearchPagination/SearchPagination"
import useInitPropertyData from "@components/SearchResults/hooks/useInitPropertyData"
import useUrlData from "@components/SearchResults/hooks/useUrlData"
import { usePropertyStore } from "@components/SearchResults/store/propertyStore"
import PropertyCopy from "@components/TextCopy/PropertyCopy"
import Seo from "@components/seo"
import { createResultsCanonicalUrl, getH1Text, getSeoDesc } from "@lib/searchUtils"
import { offplanResultsURL } from "@lib/urls"
import loadable from "@loadable/component"
import "@styles/search-results.scss"
import clsx from "clsx"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"

const PropertyMap = loadable(() =>
  import("@components/SearchResults/map/RenderMap")
)

const SearchResults = ({ location }) => {
  const [isLoad, setLoad] = useState(false)
  useEffect(() => {
    setLoad(true)
  })
  const pageUrlData = useUrlData()
  let h1Text;
  if(pageUrlData){
    h1Text = getH1Text(pageUrlData)
  }
  const path =
    location.pathname ||
    (typeof window !== "undefined" && window.location.pathname)

  useInitPropertyData()
  const showMap = usePropertyStore((state) => state.showMap)
  const searchResult = usePropertyStore((state) => state.searchResult)
  const pType = usePropertyStore((state) => state.pType)
  const pDepartment = usePropertyStore((state) => state.pDepartment)
  const selectedAreas = usePropertyStore((state) => state.selectedAreas)
  const buildingType = usePropertyStore((state) => state.buildingType)
  const { nbHits, nbPages, page } = searchResult
  // Redirect to 404 if the URL is not valid
  const matchURLs = [
    "type-",
    "above-",
    "below-",
    "between-",
    "-bedrooms",
    "status-",
    "developed-",
    "in-",
    "page-",
    "sortby-"
  ]

  const path2 = path.split("/")[2] || ""

  if (
    !matchURLs.some((matchURL) => path2.includes(matchURL)) &&
    path !== offplanResultsURL
  ) {
    typeof window !== "undefined" && navigate("/new-projects/", { replace: true })
  }

  const showCopy =
    typeof window !== "undefined" &&
    (path === `${offplanResultsURL}` || path?.includes("type-"))

  return (
    <Layout
      pageData={{
        page_class: clsx(
          "search-results-page offplan-results-page",
          nbHits === 0 && "no-properties-page"
        ),
        layout: "search-results offplan-results",
      }}
    >
      {showMap ? (
        <div className="search-map-wrapper">
          <PropertyMap />
        </div>
      ) : (
        <div className="offplan-results-wrap">
         
          <BannerBG />
          <SearchFilters />
          {/* {nbHits !== undefined &&
          <div className="dd-div"> */}
         <ResultsBreadcrumb path={path} search_type={pType} isLoad={isLoad} h1Text={h1Text ? h1Text : ""} department={"new_developments"} />
          <DeveloperInfo />
          <div className="search-results-section offplan-results-section">
          <InfoMapSort h1Text={h1Text} isLoad={isLoad} />
            <PropertyList />
            {nbPages > page &&
            <SearchPagination />}
          </div>

          {/* </div> 
          // : 
          // <div className="dd-loader"></div>
          } */}
        </div>
      )}
      {nbHits !== undefined &&<PropertyCopy
        pType={pType}
        buildingType={buildingType}
        pDepartment={pDepartment}
        selectedAreas={selectedAreas}
      />}
    </Layout>
  )
}

export const Head = () => {
  const pageUrlData = useUrlData()

  const h1Text = getH1Text(pageUrlData)
  const seoDesc = getSeoDesc(pageUrlData)
  const [customCanonical, setCustomCanonical] = useState("https://www.providentestate.com/new-projects/")

  useEffect(() => {
    const fetchCanonicalUrl = async () => {
      const canonicalUrl = await createResultsCanonicalUrl({ pageUrlData })
      setCustomCanonical(process.env.GATSBY_SITE_URL+canonicalUrl)
    }

    fetchCanonicalUrl()
  }, [pageUrlData])
  if (!customCanonical) {
    // You can return a loading state or default canonical URL here if needed
    return null
  }
  // let customCanonical = createResultsCanonicalUrl({pageUrlData})
  return <Seo 
  customCanonical={customCanonical}
  title={h1Text} description={seoDesc} />
}

export default SearchResults
