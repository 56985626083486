import { ShowProcessedImage } from "@common/ggfx-client/module/components/show-image"
import { SliderLinkComp } from "@components/PropertyCard/PropertyCard"
import PlayVideoButton from "@components/elements/PlayVideo/PlayVideoButton"
import BedIcon from "@components/icons/BedIcon"
import LocationIcon from "@components/icons/LocationIcon"
import PhoneIcon from "@components/icons/PhoneIcon"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
import useBrandInfo from "@hooks/dataHooks/useBrandInfo"
import useDeviceMedia from "@hooks/useDeviceMedia"
import noImg from "@images/noImage.png"
import { offplanResultsSaleURL, offplanResultsURL } from "@lib/urls"
import {
  addRollWhatsapp,
  getWhatsAppURL,
  getWhatsBaseURL,
  gtagReportConversion,
  parseHTML,
  slugToTitle,
  slugify,
} from "@lib/utils"
import clsx from "clsx"
import { Link } from "gatsby"
import React from "react"
import imageConfig from "../../../static/images/config.json"
import "./OffplanCard.scss"
import OffCurrencyPrice from "@components/CurrencyPrice/OffCurrencyPrice"
import { useGlobalStore } from "@stores/globalStore"
import GGFXImage from "@components/elements/GGFXImage"

const OffplanCard = ({
  image,
  images,
  buldingType,
  videoURL,
  description,
  title,
  developer,
  location,
  future_launch,
  minsBeds,
  maxBeds,
  displayBedrooms,
  slug,
  community,
  slider,
  listView,
  completionYear,
  showProcessedImage,
  ggfx_results,
  strapi_id,
  price,
  notalgo
}) => {
  const { phone } = useBrandInfo()
  const { isTablet } = useDeviceMedia()
  const { language } = useGlobalStore()
  const bedrooms =
  displayBedrooms !== null && displayBedrooms !== "0" ? displayBedrooms :
    // minbeds = 2, maxbeds = 4 =>  2, 3, 4
    Array.from({ length: maxBeds - minsBeds + 1 }, (_, i) => i + minsBeds).join(
      ", "
    )

  const buildingText = buldingType?.join(", ")

  const developerSlug = slugify(developer)
  let urlSlug = typeof slug === "string" ? slug : language?.value === "EN" ? slug.en : language?.value === "RUS" ? slug.ru : slug.en
  const propertyURL = `${offplanResultsURL + urlSlug}/`

  const LinkComponent = isTablet ? Link : Link
  const linkProps = isTablet
    ? { to: propertyURL }
    : { to: propertyURL }

  const DevComp = developerSlug ? Link : "p"

  // if completion year is in the past, then the property is ready
  const isReady = completionYear
    ? new Date(new Date(completionYear)?.setMonth(12)) < new Date()
    : false

  return (
    <div className={clsx("offplan-card-wrap", listView && "list-view")}>
      <div className="img-section">
        <div className="flag-section">
          <PlayVideoButton videoUrl={videoURL} alternateButton />
          {buildingText && (
            <p className="img-tag">
              <span>{buildingText}</span>
            </p>
          )}
          {future_launch && (
            <p className="img-tag tag-new">
              <span>{future_launch}</span>
            </p>
          )}
        </div>
        {completionYear &&
        <div className="flag-section ready-flag">
          <p className="img-tag">
            <span>{isReady ? "Ready" : completionYear}</span>
          </p>
        </div>}
        <SliderLinkComp
          slider={slider}
          url={propertyURL}
          LinkComponent={LinkComponent}
          linkProps={linkProps}
          className={clsx("img-section", listView && "listview-img-section")}
        >
          {listView ? (
            <>
              <OffplanImage
                image={image}
                listView={listView}
                index={1}
                key={`offplan-img-${1}`}
                showProcessedImage={showProcessedImage}
                ggfx_results={ggfx_results}
                strapi_id={strapi_id}
                notalgo={notalgo}
              />
              {images?.length > 0 &&
                images
                  ?.slice(0, 2)
                  ?.map((img, index) => (
                    <OffplanImage
                      image={img}
                      listView={listView}
                      index={index + 2}
                      showProcessedImage={showProcessedImage}
                      key={`offplan-img-${index + 2}`}
                      ggfx_results={ggfx_results}
                      strapi_id={strapi_id}
                      notalgo={notalgo}
                    />
                  ))}
            </>
          ) : (
            image === "no-imge" ?
           <img src={noImg} alt="offplan-img" id={`offplan-img-noimg`} /> :
            <OffplanImage
              image={image ? image : []}
              listView={listView}
              index={1}
              showProcessedImage={showProcessedImage}
              key={`offplan-img-${1}`}
              ggfx_results={ggfx_results}
              strapi_id={strapi_id}
              notalgo={notalgo}
            /> 

          )}
        </SliderLinkComp>
      </div>
      <div className="content-section">
        <LinkComponent {...linkProps} className="title">
          {title} {community ? `at ${slugToTitle(community)}` : ""}
        </LinkComponent>
        {developer && (
          <DevComp
            to={`${offplanResultsSaleURL}developed-by-${developerSlug}/`}
            className="developer"
          >
            by <span>{slugToTitle(developer)}</span>
          </DevComp>
        )}
          {price && price !== "TBA" &&
      <div className="price">
      <span>Starting Price </span>
       <OffCurrencyPrice price={price} />
        </div>}
        <div className="more-info">
          <p className="location">
            <LocationIcon blue />
            <span>{location}</span>
          </p>         
          {typeof bedrooms === "string" ? 
          <p className="beds">
          <BedIcon />
          <span>{bedrooms}</span>
        </p> :
          bedrooms > 0 ?
          <p className="beds">
            <BedIcon />
            <span>{bedrooms}</span>
          </p> : null}
        </div>
        {description && (
          <p className="description">
            <span>{parseHTML(`${typeof description === "string" ? description?.slice(0, 170) : language?.value === "EN" ? description?.en?.slice(0, 170) : language?.value === "RUS" ? description?.ru?.slice(0, 170) : description?.en?.slice(0, 170)}...`)}</span>
            <LinkComponent {...linkProps} className="read-more-text">
              more
            </LinkComponent>
          </p>
        )}
        {listView && (
          <div className="cta-section">
            {/* <Link
              to={bookAViewingURL}
              className="property-cta"
              state={{
                property: props,
                propertyURL,
              }}
            >
              <MailIcon color="black" />
              <span>Email</span>
            </Link> */}
            <a
              href={`tel:${phone}`}
              className="property-cta"
              onClick={() => gtagReportConversion(`tel:${phone}`)}
            >
              <PhoneIcon />
              <span>Phone</span>
            </a>
            <a
              href={getWhatsBaseURL(
                phone,
                `Hi, I am interested in this project ${
                  process.env.GATSBY_SITE_URL + propertyURL
                }`
              )}
              target="_blank"
              className="property-cta"
              rel="noreferrer"
              onClick={()=> addRollWhatsapp()}
              >
              <WhatsAppIcon outline />
              <span>WhatsApp</span>
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

const OffplanImage = ({ image, listView, index, showProcessedImage, ggfx_results, strapi_id, notalgo }) => {
  // console.log(image, listView, index, showProcessedImage, ggfx_results, strapi_id, notalgo)
  let Comp =  <img src={image} alt="offplan-img" id={`offplan-img-${index}`} />
  if(notalgo){
    Comp =  <GGFXImage
  ImageSrc={image}
  altText={"offplan-img"}
  imagetransforms={
    ggfx_results
  }
  renderer="srcSet"
  imagename="off-plan.images.tileimg"
  strapiID={strapi_id}
/>
  }
  else if (image) {
    if (showProcessedImage) {
      Comp = (
        <ShowProcessedImage
          images={image}
          attr={{
            alt: "offplan-img",
            id: `property-image-${index}`,
          }}
          transforms={imageConfig.property.searchResults.sizes}
        />
      )
    }
   
  } else {
    Comp = <img src={noImg} alt="offplan-img" id={`offplan-img-${index}`} />
  }

  return (
    <div className={clsx("img-zoom", listView && "listview-img")}>{Comp}</div>
  )
}

export default OffplanCard
